import React, { useState } from "react";
import {
  ANALYTICS_ELEMENT_NAME,
  ANALYTICS_ELEMENT_TYPE,
  ANALYTICS_LOCATION,
  ANALYTICS_MODULE,
  IS_MOBILE,
} from "constants/index";
import { useHistory } from "react-router";
import Flex from "app/components/Flex";
import Icon, { PlusBox, Globe, Lock } from "app/components/Icon";
import { H1, H2 } from "app/components/Typography";
import {
  SaveIconWithTextButton,
  ShareIconWithTextButton,
} from "app/components/Button/IconWithTextButton";
import { NotFound } from "app/components/NotFound";
import LoaderFullCentered from "app/components/Loader/LoaderFullCentered";
import { Waypoint } from "app/components/Waypoint";
import {
  contentShareContent,
  playlistsSelectElement,
} from "services/typewriter/segment";
import env from "helpers/env";
import Breadcrumbs from "app/components/Breadcrumbs";
import {
  EditPlaylistModal,
  DeletePlaylistModal,
  SharePlaylistModal,
} from "app/components/PlaylistComponents/PlaylistModals";
import { useGetUserIsAnonymous } from "modules/selectors";
import { usePreviousLocation } from "hooks/usePreviousLocation";
import { AddClassesModal } from "../AddClassesModal";
import { CtaButton } from "../styles";
import { StudioClassRow } from "../PlaylistClassRow";
import { PlaylistInfo, PlaylistOwner } from "./styles";
import { useGetPlaylist, useGetPlaylistStudioClasses } from "../hooks";
import { useSavePlaylist, useUnsavePlaylist } from "./hooks";
import { DropdownMenuOptions } from "./DropdownMenuOptions";

interface Props {
  playlistId: string;
}

export const PlaylistPage = ({ playlistId }: Props) => {
  const history = useHistory();
  const { previousLocation } = usePreviousLocation();
  const isAnonymous = useGetUserIsAnonymous();

  const { playlist, loadingPlaylist, refetchPlaylist } = useGetPlaylist({
    playlistId,
    studioClassIds: [],
  });
  const {
    studioClasses,
    loadingPlaylistStudioClasses,
    fetchMorePlaylistStudioClasses,
    pageInfoPlaylistStudioClasses,
  } = useGetPlaylistStudioClasses({
    playlistId,
    studioClassIds: [],
  });
  const { savePlaylist } = useSavePlaylist({ playlistId });
  const { unsavePlaylist } = useUnsavePlaylist({ playlistId });
  const [isAddClassesModalOpen, setIsAddClassesModalOpen] = useState(false);
  const [isEditPlaylistModalOpen, setIsEditPlaylistModalOpen] = useState(false);
  const [isDeletePlaylistModalOpen, setIsDeletePlaylistModalOpen] = useState(
    false
  );
  const [isSharePlaylistModalOpen, setIsSharePlaylistModalOpen] = useState(
    false
  );

  if (loadingPlaylist) {
    return <LoaderFullCentered />;
  }

  if (!playlist) {
    return <NotFound />;
  }

  const saveOrUnsavePlaylist = () => {
    if (playlist.isSaved) {
      unsavePlaylist();
    } else {
      savePlaylist();
    }
  };

  const openAddClassesToPlaylistModal = () => {
    playlistsSelectElement({
      element_name: ANALYTICS_ELEMENT_NAME.playlistAddClassesButton,
      element_type: ANALYTICS_ELEMENT_TYPE.button,
      location: ANALYTICS_LOCATION.playlists,
      module: ANALYTICS_MODULE.playlist_details,
    });
    setIsAddClassesModalOpen(true);
  };

  const shouldShowSavePlaylistButton = !isAnonymous && !playlist.owner?.isMe;

  return (
    <>
      <Flex flexDirection="column" p="24px">
        <Breadcrumbs
          onClick={() => {
            if (!previousLocation) {
              history.push("/playlists");
            } else if (previousLocation.pathname.includes("playlist")) {
              history.goBack();
            } else {
              history.push("/playlists");
            }
          }}
        >
          &lt; Back to Playlists
        </Breadcrumbs>
        <H1>{playlist.name}</H1>
        <Flex my="8px" gap="12px" alignItems="center">
          {playlist.owner && (
            <>
              <PlaylistOwner to={`/@${playlist.owner.username}`}>
                @{playlist.owner.username}
              </PlaylistOwner>
              <div> • </div>
            </>
          )}
          <PlaylistInfo>
            {`${playlist.studioClasses.totalCount} ${
              playlist.studioClasses.totalCount === 1 ? "Class" : "Classes"
            }`}
          </PlaylistInfo>
          <div> • </div>
          <PlaylistInfo>
            <Flex alignItems="center">
              <Icon as={playlist.hidden ? Lock : Globe} mr="4px" />
              {playlist.hidden ? "Private" : "Public"}
            </Flex>
          </PlaylistInfo>
        </Flex>
        <Flex gap="24px" alignItems="center">
          {shouldShowSavePlaylistButton && (
            <SaveIconWithTextButton
              isSaved={playlist.isSaved}
              onClick={() => saveOrUnsavePlaylist()}
            />
          )}
          <ShareIconWithTextButton
            onClick={() => {
              contentShareContent({
                content_id: playlist.id,
                content_type: "playlist",
                content_title: playlist.name,
                location: ANALYTICS_LOCATION.playlists,
                module: ANALYTICS_MODULE.playlist_details,
              });
              if (IS_MOBILE) {
                navigator.share({
                  url: `${env("PUBLIC_DOMAIN")}/playlists/${playlist.id}`,
                  text: "Check out this STEEZY Studio playlist!",
                  title: playlist.name,
                });
              } else {
                setIsSharePlaylistModalOpen(true);
              }
            }}
          />
          {playlist.owner?.isMe && (
            <DropdownMenuOptions
              openAddClassesModal={() => openAddClassesToPlaylistModal()}
              openEditPlaylistModal={() => setIsEditPlaylistModalOpen(true)}
              openDeletePlaylistModal={() => setIsDeletePlaylistModalOpen(true)}
            />
          )}
        </Flex>
        <Flex flexDirection="column" my="16px" gap="16px">
          {studioClasses &&
            studioClasses.length > 0 &&
            studioClasses.map(studioClass => (
              <StudioClassRow
                key={studioClass.id}
                studioClass={studioClass}
                playlistId={playlist.id}
              />
            ))}
          {!loadingPlaylistStudioClasses && (
            <Waypoint
              hasMore={pageInfoPlaylistStudioClasses?.hasNextPage}
              fetchData={fetchMorePlaylistStudioClasses}
            />
          )}
        </Flex>
        {playlist.owner?.isMe && (
          <Flex flexDirection="column">
            <H2>Let&apos;s find some classes to add to this playlist</H2>
            <Flex mt="8px">
              <CtaButton onClick={() => openAddClassesToPlaylistModal()}>
                <Icon as={PlusBox} color="white" height="16px" width="16px" />
                Add Classes
              </CtaButton>
            </Flex>
          </Flex>
        )}
      </Flex>
      {isAddClassesModalOpen && (
        <AddClassesModal
          closeModal={() => {
            setIsAddClassesModalOpen(false);
            refetchPlaylist();
          }}
          playlistId={playlistId}
        />
      )}
      {isEditPlaylistModalOpen && (
        <EditPlaylistModal
          playlist={playlist}
          closeModal={() => setIsEditPlaylistModalOpen(false)}
        />
      )}
      {isDeletePlaylistModalOpen && (
        <DeletePlaylistModal
          playlist={playlist}
          closeModal={() => setIsDeletePlaylistModalOpen(false)}
        />
      )}
      {isSharePlaylistModalOpen && (
        <SharePlaylistModal
          closeModal={() => setIsSharePlaylistModalOpen(false)}
          playlist={playlist}
        />
      )}
    </>
  );
};
